import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Policies.css';
import HeroSection from '../components/HeroSection.js';
import Footer from '../components/Footer.js';

import privacyPolicy from '../assets/policies/P2Cx_002_Privacy_Notice_Health_and_Social_Care.pdf'
import personalDataConsentForm from '../assets/policies/P2Cx_004_RS_Care_Professional_Consent_Form_for_Personal_Data.pdf'
import retentionSchedule from '../assets/policies/P2Cx_003_Retention_Schedule_for_RS_Care_Professional.pdf'
import feedbackAndReviews from '../assets/policies/P1Cx_001_I_Value_Your_Feedback.pdf'
import complaints from '../assets/policies/P1Cx_002_What_to_Do_If_You_Are_Not_Happy.pdf'
import safeguardingPolicy from '../assets/policies/P3Cx_002_RS_Care_Professional_Safeguarding_Policy.pdf'
import safeguardingReportStatement from '../assets/policies/P3Cx_001_Report_Statement_for_Safeguarding_Concerns.pdf'

const policiesData = [
    {
        title: 'Privacy Notice Policy',
        id: 'P2Cx_002',
        effective: 'November 05th, 2024',
        nextReview: 'November 05th, 2025',
        reviewedBy: 'Ryan Skelton',
        downloadUrl: privacyPolicy,
        content: `
            <h3>What to Expect</h3>
            <p>This privacy notice tells you what to expect us to do with your personal information.</p>

            <h3>Contact details</h3>
            <p><strong>Post:</strong> 3 Northfield Road, ILFRACOMBE, Devon, EX34 8AL, GB</p>
            <p><strong>Telephone:</strong> 07818744076</p>
            <p><strong>Email:</strong> rscareprofessional@gmail.com</p>

            <h3>What information we collect, use, and why</h3>
            <p>We collect or use the following information to provide patient care, services, pharmaceutical products and other goods:</p>
            <ul>
                <li>Name, address and contact details</li>
                <li>Gender</li>
                <li>Pronoun preferences</li>
                <li>Date of birth</li>
                <li>NHS/HSC/CHI number</li>
                <li>National Insurance number</li>
                <li>Next of Kin details including any support networks</li>
                <li>Emergency contact details</li>
                <li>Photographs</li>
                <li>Health information (including medical conditions, allergies, medical requirements and medical history)</li>
                <li>Information about care needs (including disabilities, home conditions, medication and dietary requirements and general care provisions)</li>
                <li>Test results (including psychological evaluations, scans, bloods, x-rays, tissue tests and genetic tests)</li>
                <li>Payment details (including card or bank information for transfers and direct debits)</li>
                <li>Records of meetings and decisions</li>
                <li>Call recordings</li>
                <li>Information about income and financial needs for funding or personal budget support</li>
            </ul>

            <p>We also collect the following information for safeguarding or public protection reasons:</p>
            <ul>
                <li>Racial or ethnic origin</li>
                <li>Religious or philosophical beliefs</li>
                <li>Health information</li>
            </ul>

            <h3>Lawful bases and data protection rights</h3>
            <p>Under UK data protection law, we must have a “lawful basis” for collecting and using your personal information. You have rights regarding your personal data. For more details, visit the <a href="https://www.ico.org.uk">ICO website</a>.</p>

            <h3>Our lawful bases for the collection and use of your data</h3>
            <p>Our lawful bases for collecting or using personal information to provide patient care, services, pharmaceutical products, and other goods are:</p>
            <ul>
                <li>Consent</li>
                <li>Contract</li>
                <li>Legal obligation</li>
            </ul>

            <h3>Where we get personal information from</h3>
            <p>We may collect personal information from various sources including:</p>
            <ul>
                <li>Directly from you</li>
                <li>Regulatory authorities</li>
                <li>Family members or carers</li>
                <li>Other health and care providers</li>
                <li>Social services</li>
                <li>Charities or voluntary sector organisations</li>
                <li>Councils and other public sector organisations</li>
                <li>Relevant regulatory authorities</li>
                <li>Suppliers and service providers</li>
            </ul>

            <h3>How long we keep information</h3>
            <p>Our retention Schedule will be placed inside each client's unique care plan for client/customer reference. A copy can also be obtained via email at rscareprofessional@gmail.com with the subject “ref: Retention Schedule”</p>

            <h3>Who we share information with</h3>
            <p>We may share personal information with:</p>
            <ul>
                <li>Other health providers (e.g. GPs and consultants)</li>
                <li>Insurance companies, brokers, and other intermediaries</li>
                <li>Care providers</li>
                <li>Organisations we need to share information with for safeguarding reasons</li>
                <li>Emergency services</li>
                <li>Professional advisors</li>
                <li>Legal bodies or authorities</li>
                <li>Local authorities or councils</li>
                <li>Relevant regulatory authorities</li>
                <li>External auditors or inspectors</li>
            </ul>

            <h3>Duty of confidentiality</h3>
            <p>We are subject to a common law duty of confidentiality. However, there are circumstances where we will share relevant health and care information. These are where:</p>
            <ul>
                <li>You’ve provided us with your consent</li>
                <li>We have a legal requirement (including court orders) to collect, share or use the data</li>
                <li>In the public interest to collect, share and use the data</li>
            </ul>

            <h3>How to complain</h3>
            <p>If you have any concerns about our use of your personal data, you can make a complaint to us using the contact details above. If you remain unhappy, you can also complain to the Information Commissioner’s Office (ICO).</p>
        `
    },
    {
        title: 'Personal Data Consent Form',
        id: 'P2Cx_004',
        effective: 'November 05th, 2024',
        nextReview: 'November 05th, 2025',
        reviewedBy: 'Ryan Skelton',
        downloadUrl: personalDataConsentForm,
        content: `
            <h3>Your Privacy and Consent</h3>
            <p>At RS Care Professional, I am committed to protecting and respecting your privacy. I require your consent to collect, use, and share your personal information as detailed in my Privacy Notice. Please read this form carefully before giving your consent.</p>
            
            <h3>Your Consent</h3>
            <p>By signing this form, you confirm that you understand and agree to the collection, use, and sharing of your personal information as outlined in my Privacy Notice, which includes:</p>
            <ul>
                <li><strong>Personal Information</strong> (e.g., name, contact details, date of birth, etc.)</li>
                <li><strong>Health Information</strong> (e.g., medical records, test results, care needs, etc.)</li>
                <li><strong>Special Categories of Data</strong> (e.g., racial/ethnic origin, religious beliefs, sexual orientation, etc.)</li>
                <li><strong>Financial Information</strong> (e.g., payment details, funding needs, etc.)</li>
                <li><strong>Safeguarding Information</strong> (e.g., records related to public protection and care provision)</li>
            </ul>
            
            <h3>Why I Collect Your Data</h3>
            <p>I collect your personal data for the following purposes:</p>
            <ul>
                <li>To provide appropriate health and social care services.</li>
                <li>To communicate with you or your next of kin.</li>
                <li>To comply with legal and regulatory requirements.</li>
                <li>To support safeguarding and public protection.</li>
            </ul>
            
            <h3>How I Share Your Data</h3>
            <p>I may share your personal data with:</p>
            <ul>
                <li>Health care providers (GPs, consultants, etc.)</li>
                <li>Regulatory bodies (for safeguarding purposes)</li>
                <li>Emergency services (in case of emergencies)</li>
                <li>Local authorities or councils (where necessary)</li>
            </ul>
            <p>You can find full details on how I collect, use, and share your data in my Privacy Notice.</p>
            
            <h3>Consent Preferences</h3>
            <p>Please tick the boxes below to confirm your consent for each category:</p>
            <ul>
                <li>
                    <input type="checkbox" id="personalData" />
                    <label for="personalData">Personal Data: I consent to the collection and use of my personal data (name, contact details, date of birth, etc.) for care provision and communication purposes.</label>
                </li>
                <li>
                    <input type="checkbox" id="healthInformation" />
                    <label for="healthInformation">Health Information: I consent to the collection, use, and sharing of my health information (medical records, care needs, etc.) to receive appropriate care and treatment.</label>
                </li>
                <li>
                    <input type="checkbox" id="specialCategoryData" />
                    <label for="specialCategoryData">Special Category Data: I consent to the collection and use of special category data (racial/ethnic origin, religious beliefs, sexual orientation, etc.) for safeguarding purposes.</label>
                </li>
                <li>
                    <input type="checkbox" id="financialInformation" />
                    <label for="financialInformation">Financial Information: I consent to the collection and use of my financial information for managing payments and funding needs.</label>
                </li>
                <li>
                    <input type="checkbox" id="safeguardingInformation" />
                    <label for="safeguardingInformation">Safeguarding Information: I consent to the collection and use of safeguarding information to protect my well-being and comply with public protection requirements.</label>
                </li>
            </ul>
            
            <h3>Your Rights</h3>
            <p>You have the right to:</p>
            <ul>
                <li>Withdraw your consent at any time by contacting me using the details below.</li>
                <li>Request access to your personal data.</li>
                <li>Request correction or deletion of inaccurate data.</li>
                <li>Object to the processing of your data.</li>
            </ul>
            <p>For more information on your rights, please refer to my Privacy Notice.</p>
            
            <h3>Contact Information</h3>
            <p>If you have any questions or wish to withdraw your consent, please contact me:</p>
            <p><strong>Post:</strong> 3 Northfield Road, Ilfracombe, Devon EX34 8AL</p>
            <p><strong>Phone:</strong> 07818744076</p>
            <p><strong>Email:</strong> <a href="mailto:rscareprofessional@gmail.com">rscareprofessional@gmail.com</a></p>
            
            <h3>Signature and Consent</h3>
            <p>I have read and understood the information in this consent form and the accompanying Privacy Notice. I consent to RS Care Professional collecting, using, and sharing my personal data as described above.</p>
            <p><strong>Name:</strong> ___________________________________</p>
            <p><strong>Signature:</strong> ________________________________</p>
            <p><strong>Date:</strong> ____________________________________</p>
        `
    },
    {
        title: 'Retention Schedule',
        id: 'P2Cx_003',
        effective: 'November 05th, 2024',
        nextReview: 'November 05th, 2025',
        reviewedBy: 'Ryan Skelton',
        downloadUrl: retentionSchedule,
        content: 
        `
            <p>This document outlines the retention periods for personal data processed by RS Care Professional in accordance with the UK GDPR and the Data Protection Act 2018.</p>
            
            <h4>1. Personal Information Collected</h4>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Data Type</th>
                        <th>Purpose</th>
                        <th>Lawful Basis</th>
                        <th>Retention Period</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Name, address, contact details</td>
                        <td>For patient care and correspondence</td>
                        <td>Contractual, Legal Obligation</td>
                        <td>7 years after the end of service</td>
                        <td>To comply with health regulations and for potential disputes.</td>
                    </tr>
                    <tr>
                        <td>Date of birth</td>
                        <td>To identify the individual for care purposes</td>
                        <td>Contractual, Legal Obligation</td>
                        <td>7 years after the end of service</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>NHS/HSC/CHI number</td>
                        <td>For health-related services and coordination</td>
                        <td>Contractual, Legal Obligation</td>
                        <td>7 years after the end of service</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Next of Kin details</td>
                        <td>For emergency contact purposes</td>
                        <td>Legitimate Interest</td>
                        <td>7 years after the end of service</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Health information (medical records)</td>
                        <td>For providing care and managing treatments</td>
                        <td>Contractual, Legal Obligation</td>
                        <td>7 years after the end of service</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Test results (scans, blood tests)</td>
                        <td>For diagnosis and treatment</td>
                        <td>Contractual, Legal Obligation</td>
                        <td>7 years after the end of service</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Photographs</td>
                        <td>For identification and care purposes</td>
                        <td>Consent</td>
                        <td>Until consent is withdrawn or 7 years</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Payment information</td>
                        <td>For financial transactions</td>
                        <td>Contractual</td>
                        <td>7 years for tax and legal purposes</td>
                        <td>Required for tax purposes.</td>
                    </tr>
                    <tr>
                        <td>Call recordings</td>
                        <td>For record-keeping and training purposes</td>
                        <td>Legitimate Interest</td>
                        <td>12 months</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Racial/ethnic origin, religious beliefs, sexual orientation</td>
                        <td>For safeguarding, care provision</td>
                        <td>Explicit Consent, Legal Obligation</td>
                        <td>7 years after the end of service</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Correspondence (emails, letters)</td>
                        <td>For managing queries, complaints, or claims</td>
                        <td>Legitimate Interest</td>
                        <td>7 years after the end of service</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            
            <h4>2. Special Categories of Personal Data</h4>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Data Type</th>
                        <th>Purpose</th>
                        <th>Lawful Basis</th>
                        <th>Retention Period</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Health data (medical history, disabilities, allergies)</td>
                        <td>For managing patient care</td>
                        <td>Legal Obligation, Contractual</td>
                        <td>7 years after the end of service</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Safeguarding data</td>
                        <td>For public protection and legal compliance</td>
                        <td>Legal Obligation</td>
                        <td>7 years or as required by law</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Criminal offence data</td>
                        <td>For legal and safeguarding purposes</td>
                        <td>Legal Obligation</td>
                        <td>As long as required by law</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            
            <h4>3. Records of Meetings, Decisions, and Incident Reports</h4>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Data Type</th>
                        <th>Purpose</th>
                        <th>Lawful Basis</th>
                        <th>Retention Period</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Records of meetings/decisions</td>
                        <td>For care planning and review purposes</td>
                        <td>Legitimate Interest, Legal Obligation</td>
                        <td>7 years after the end of service</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Incident reports (including health and safety incidents)</td>
                        <td>For internal audits and compliance</td>
                        <td>Legal Obligation, Legitimate Interest</td>
                        <td>7 years after incident resolution</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            
            <h4>4. Financial Information</h4>
            <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                    <tr>
                        <th>Data Type</th>
                        <th>Purpose</th>
                        <th>Lawful Basis</th>
                        <th>Retention Period</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Income details, financial needs</td>
                        <td>For funding support and care planning</td>
                        <td>Contractual</td>
                        <td>7 years</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Bank details, transaction records</td>
                        <td>For payment processing</td>
                        <td>Contractual</td>
                        <td>7 years for audit and tax purposes</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            
            <h4>5. Retention Review Process</h4>
            <p>All data will be reviewed on an annual basis to ensure that no data is retained longer than necessary and in compliance with legal requirements. Personal data that is no longer needed will be securely deleted or anonymized.</p>
            
            <h3>How Long We Keep Your Information</h3>
            <p>We will keep your personal data for as long as necessary to fulfil the purposes for which it was collected and to comply with legal, regulatory, or contractual requirements. For example:</p>
            <ul>
                <li>Health records: 7 years after the end of care provision.</li>
                <li>Financial records: 7 years for tax and audit purposes.</li>
                <li>Safeguarding information: 7 years or as required by law.</li>
            </ul>
            
            <p>After these periods, personal data will either be securely deleted or anonymized for statistical analysis.</p>
        `
    },
    {
        title: 'Feedback & Reviews',
        id: 'P1Cx_001',
        effective: 'November 05th, 2024',
        nextReview: 'November 05th, 2025',
        reviewedBy: 'Ryan Skelton',
        downloadUrl: feedbackAndReviews,
        content: 
        `
            <h3>I Value Your Feedback</h3>
            <p>At RS Care Professional, I believe in continuously improving the care and support I provide. Your feedback is essential in helping me enhance my services and ensure that I meet your expectations. If you have recently used my services, I would love to hear your thoughts!</p>

            <p>Please take a moment to share your experience. Your valuable insights will help others make informed decisions, and with your permission, I may feature your review on my website and social media platforms.</p>

            <h4>How to leave a review:</h4>
            <ul>
                <li>You can email me directly at <a href="mailto:rscareprofessional@gmail.com">rscareprofessional@gmail.com</a> with your review.</li>
                <li>Alternatively, feel free to leave feedback through my writing addressed to:</li>
            </ul>

            <p><strong>Ryan Skelton</strong><br>
            RS CARE PROFESSIONAL<br>
            3 Northfield Road,<br>
            Ilfracombe, Devon, EX34 8AL.</p>

            <p>Thank you for taking the time to share your thoughts—it helps me improve and allows me to continue providing top-quality care.</p>
        `,
    },
    {
        title: 'Complaints',
        id: 'P1Cx_002',
        effective: 'November 05th, 2024',
        nextReview: 'November 05th, 2025',
        reviewedBy: 'Ryan Skelton',
        downloadUrl: complaints,
        content: 
        `
            <h3>What to Do If You Are Not Happy</h3>
            <p>At RS Care Professional, I am committed to providing the highest quality of care and support. However, I understand that sometimes things may not meet your expectations. If this happens, I want to make it as easy as possible for you to raise any concerns, so I can address them promptly and effectively.</p>

            <h4>Step 1: Talk to Me</h4>
            <p>The best way to resolve a concern is by discussing it directly. If something is bothering you or you’re unhappy with any aspect of the care, please feel free to speak with me. I take all concerns seriously and will listen carefully to understand the issue fully. Together, we can explore how to make things right.</p>

            <h4>Step 2: Put It in Writing</h4>
            <p>If you prefer, you can put your concern in writing. You can email me directly at <a href="mailto:rscareprofessional@gmail.com">rscareprofessional@gmail.com</a>, or send a letter to:</p>
            <p><strong>RS CARE PROFESSIONAL</strong><br>
            3 Northfield Road,<br>
            Ilfracombe, Devon, EX34 8AL.</p>
            <p>In your message, please include as much detail as possible about the issue, so I can respond with a clear understanding of your concern.</p>

            <h4>Step 3: How I Will Respond</h4>
            <p>I will acknowledge your concern within 2 working days and aim to provide a full response within 10 working days. In the rare case that I need more time, I will let you know and keep you informed of progress. My goal is always to resolve matters to your satisfaction as swiftly as possible.</p>

            <h4>Step 4: Further Support</h4>
            <p>If, after my response, you feel that your concerns have not been fully addressed, I can provide information on independent bodies or advocacy services that can offer additional support and advice.</p>
            <p>For advocacy and support services in North Devon/Devon, you can refer clients to the Devon Advocacy Consortium, which provides free, independent advocacy services for people over 16. They cover various areas, including:</p>
            <ul>
                <li>Independent Care Act Advocacy</li>
                <li>Independent Mental Health Advocacy</li>
                <li>Independent Mental Capacity Advocacy</li>
                <li>NHS Complaints Advocacy (Devon only)</li>
            </ul>
            <p>The Devon Advocacy Consortium can be contacted at <a href="tel:01392822377">01392 822377</a> or via email at <a href="mailto:devonadvocacy@livingoptions.org">devonadvocacy@livingoptions.org</a>.</p>

            <h3>Your Feedback Matters</h3>
            <p>Please know that your feedback—positive or negative—helps me improve the services I provide. If you have any suggestions for how I can enhance your experience, I am always open to hearing them.</p>

        `,
    },
    {
        title: 'Safeguarding Policy',
        id: 'P3Cx_002', 
        effective: 'October 28th, 2024',
        nextReview: 'October 28th, 2025',
        reviewedBy: 'Ryan Skelton',
        downloadUrl: safeguardingPolicy,
        content: 
        `
            <h4>1. Introduction</h4>
            <p>RS Care Professional is committed to promoting the safety and well-being of all clients receiving care. This policy outlines how safeguarding concerns will be addressed and managed in compliance with the Care Act 2014, the Mental Capacity Act 2005, and UK safeguarding guidelines.</p>

            <h4>2. Purpose of the Policy</h4>
            <ul>
                <li>Ensure the protection of adults at risk of harm or abuse.</li>
                <li>Provide clear guidelines for identifying and responding to safeguarding concerns.</li>
                <li>Promote a culture of respect, dignity, and safety for all clients.</li>
                <li>Ensure compliance with legal and regulatory safeguarding requirements.</li>
            </ul>

            <h4>3. Definitions</h4>
            <ul>
                <li><strong>Adult at Risk:</strong> Any person aged 18 or over who requires care and support, and is at risk of harm, abuse, or neglect.</li>
                <li><strong>Safeguarding:</strong> Protecting an adult’s right to live safely, free from abuse or neglect.</li>
                <li><strong>Abuse:</strong> Includes physical, emotional, sexual, financial, or psychological harm, as well as neglect and acts of omission.</li>
            </ul>

            <h4>4. Types of Abuse</h4>
            <p>Types of abuse may include, but are not limited to:</p>
            <ul>
                <li><strong>Physical abuse:</strong> Assault, hitting, slapping, pushing, misuse of medication, or inappropriate restraint.</li>
                <li><strong>Sexual abuse:</strong> Unwanted sexual activity, including indecent exposure, harassment, or sexual exploitation.</li>
                <li><strong>Emotional or psychological abuse:</strong> Threats of harm, controlling behaviour, intimidation, or coercion.</li>
                <li><strong>Financial abuse:</strong> Theft, fraud, or exploitation of a person’s financial resources.</li>
                <li><strong>Neglect and acts of omission:</strong> Failure to provide necessary care, support, or medical attention.</li>
                <li><strong>Self-neglect:</strong> Where a person fails to care for themselves in a way that results in serious harm.</li>
            </ul>

            <h4>5. Recognising Abuse and Neglect</h4>
            <p>As a care professional, it is your responsibility to remain vigilant for signs of abuse and neglect. Indicators may include:</p>
            <ul>
                <li>Unexplained injuries or a series of injuries.</li>
                <li>Sudden changes in behaviour or mood.</li>
                <li>Deterioration in personal hygiene or appearance.</li>
                <li>Signs of fear or distress.</li>
                <li>Financial irregularities or missing possessions.</li>
                <li>Withdrawal from regular activities or social isolation.</li>
            </ul>

            <h4>6. Responding to Safeguarding Concerns</h4>
            <p>If you suspect that a client is at risk of abuse or neglect, take the following steps:</p>
            <ol>
                <li><strong>Step 1: Immediate Action</strong><br>
                    Ensure Safety: If the client is in immediate danger, take steps to ensure their safety. This may involve contacting emergency services (999) or removing the client from the source of harm.
                </li>
                <li><strong>Step 2: Report the Concern</strong><br>
                    Recording: Document the concern in detail, including the nature of the issue, any observations, and the client’s words (if applicable). Records should be accurate, factual, and made as soon as possible.<br>
                    Report: You must raise the concern with the appropriate authorities, such as adult social services or the local safeguarding team. If the client has a social worker, inform them directly.
                </li>
                <li><strong>Step 3: Seek Guidance</strong><br>
                    Support: If you're unsure of what to do next, you can seek advice from your local authority safeguarding team or adult social services. They will provide further instructions on reporting and managing the concern.
                </li>
            </ol>

            <h4>7. Mental Capacity and Safeguarding</h4>
            <p>The Mental Capacity Act 2005 requires that all adults are assumed to have capacity unless proven otherwise. When safeguarding concerns arise, consider whether the client has the mental capacity to make informed decisions regarding their safety and care. If there are doubts, consult with health professionals or social services to assess capacity.</p>
            <p>If a client lacks capacity, decisions must be made in their best interests, and safeguarding measures should take this into account.</p>

            <h4>8. Confidentiality</h4>
            <p>Safeguarding concerns must be handled confidentially. Information should only be shared with relevant authorities involved in the safeguarding process, and only when necessary to protect the client from harm. Personal information must not be disclosed to others without the client’s consent unless required by law (e.g., serious crime or life-threatening situations).</p>

            <h4>9. Safeguarding Roles and Responsibilities</h4>
            <p>As a self-employed care professional, you hold the following responsibilities:</p>
            <ul>
                <li><strong>Prevention:</strong> Take steps to prevent abuse by providing high-quality care and maintaining clear communication with clients and their families.</li>
                <li><strong>Detection:</strong> Stay alert to the signs of abuse or neglect and respond promptly to any concerns.</li>
                <li><strong>Reporting:</strong> Raise any safeguarding concerns with the relevant authorities immediately.</li>
                <li><strong>Training:</strong> Ensure you are up to date with safeguarding training and remain informed of safeguarding policies and procedures.</li>
            </ul>

            <h4>10. Working with Other Agencies</h4>
            <p>RS Care Professional will work in collaboration with other professionals, including health and social care providers, police, and local safeguarding boards, to ensure the safety and well-being of clients. When concerns are raised, full cooperation will be given to safeguarding investigations.</p>

            <h4>11. Recording and Documentation</h4>
            <p>It is essential to keep detailed and accurate records of any safeguarding concerns, actions taken, and outcomes. Records must include:</p>
            <ul>
                <li>The date and time of the concern.</li>
                <li>A description of the concern or incident.</li>
                <li>Details of who was involved or present.</li>
                <li>The actions taken, including who was notified.</li>
                <li>Any follow-up actions required or completed.</li>
            </ul>
            <p>All safeguarding records must be kept securely and in accordance with data protection laws.</p>

            <h4>12. Policy Review</h4>
            <p>This safeguarding policy will be reviewed annually or in response to changes in legislation or guidance. Any updates will be reflected in the policy and made available to clients or other stakeholders as necessary.</p>

            <h4>Contacts</h4>
            <p>If you suspect that a vulnerable adult is being abused, or you are being abused yourself and live within Devon Local Authority boundaries, please contact Adult Social Care on <a href="tel:03451551007">0345 1551 007</a> (Monday to Thursday 9.00 am to 5.00 pm, Friday 9.00 am to 4.30 pm, and Saturday 9.00 am to 5.00 pm).</p>
            <p>Outside of the hours above or on bank holidays, call the Emergency Duty Service on <a href="tel:03456000388">0345 6000 388</a>.</p>
            <p>Alternatively, you can email <a href="mailto:adultsc.safeguardingadultservices-mailbox@devon.gov.uk">adultsc.safeguardingadultservices-mailbox@devon.gov.uk</a> (Devon Safeguarding Adults Service).</p>

            <h4>For concerns or queries regarding this policy, contact me below:</h4>
            <p><strong>RS Care Professional</strong><br>
            Ryan Skelton<br>
            Phone: <a href="tel:07818744076">07818744076</a><br>
            Email: <a href="mailto:rscareprofessional@gmail.com">rscareprofessional@gmail.com</a></p>
        `,
    },
    {
        title: 'Report Statement for Safeguarding Concerns',
        id: 'P3Cx_003',
        effective: 'October 10th, 2024',
        nextReview: 'October 10th, 2025',
        reviewedBy: 'Ryan Skelton',
        downloadUrl: safeguardingReportStatement,
        content: 
        `
            <h3>Report Statement for Safeguarding Concerns</h3>
            <p>As a registered care professional, I have a legal and ethical obligation to safeguard the well-being of all individuals under my care. If I see, hear, or become aware of anything that raises concerns regarding abuse, neglect, or the risk of harm to a vulnerable adult, I am required by law to take immediate action.</p>
            <p>This includes but is not limited to:</p>
            <ul>
                <li>Any form of abuse (physical, emotional, sexual, financial, or psychological).</li>
                <li>Neglect or acts of omission.</li>
                <li>Self-neglect.</li>
                <li>Concerns relating to mental capacity or undue influence.</li>
                <li>Any situation where an individual’s safety is at risk.</li>
            </ul>

            <p>I am obligated to report such concerns without delay to the appropriate authorities, including:</p>
            <ol>
                <li><strong>Devon and Torbay Adult Safeguarding Team</strong><br>
                    Phone: <a href="tel:03451551007">0345 155 1007</a><br>
                    Email: <a href="mailto:customerrelationssecure-mailbox@devon.gov.uk">customerrelationssecure-mailbox@devon.gov.uk</a> (secure email)<br>
                    Out of hours: <a href="tel:03456000388">0345 600 0388</a>
                </li>
                <li><strong>Devon and Cornwall Police (for criminal matters or immediate danger)</strong><br>
                    Emergency: <a href="tel:999">999</a><br>
                    Non-emergency: <a href="tel:101">101</a>
                </li>
            </ol>

            <p>I will ensure that any reports are made in accordance with the Care Act 2014 and local safeguarding procedures, and I will cooperate fully with any safeguarding investigations or actions required by the authorities.</p>

            <p>Failure to report any concerns or suspicions of abuse or neglect could result in disciplinary action, legal consequences, and compromise the safety and dignity of those in my care.</p>

            <p><strong>Sign Provider:</strong> R.Skelton | <strong>Name:</strong> Ryan Skelton.</p>
            <p><strong>Date:</strong> 10/10/2024.</p>
        
        `,
    }
];

const Policies = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [currentPolicy, setCurrentPolicy] = useState(null);
  
    const openModal = (policy) => {
      setCurrentPolicy(policy);
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
      setCurrentPolicy(null);
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className='policies-page'>
            <nav className="navbar">
                <button className="menu-toggle" onClick={toggleMenu}>
                    &#9776;
                </button>
                <nav className={isMenuOpen ? 'nav open' : 'nav'}>
                    <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </nav>
            </nav>
          <HeroSection title='Policies & Procedures' />
          <section className='policy-grid'>
            {policiesData.map((policy, index) => (
              <div key={index} className='policy-item' onClick={() => openModal(policy)}>
                <h2>{policy.title}</h2>
                <p><em>{policy.id}</em></p>
                <i className="fa-solid fa-magnifying-glass"></i>
                <p><strong>Effective:</strong> <em>{policy.effective}</em></p>
                <p><strong>Next Review:</strong> <em>{policy.nextReview}</em></p>
                <p><strong>Reviewed By:</strong> <em>{policy.reviewedBy}</em></p>
              </div>
            ))}
          </section>
    
          {/* Modal */}
          {modalOpen && (
            <div className="modal-overlay" role="dialog" aria-modal="true" aria-labelledby="modal-title" aria-describedby="modal-description">
              <div className="modal-content">
                <h2 id="modal-title">{currentPolicy.title}</h2>
                <p id="modal-description"><strong>Policy ID:</strong> <em>{currentPolicy.id}</em></p>
                <div className="line-break"></div>
                <div className="policy-details" dangerouslySetInnerHTML={{ __html: currentPolicy.content }} />
                
                <a href={currentPolicy.downloadUrl} download className="download-button">
                    Download
                </a>
                
                <button className="close-modal" onClick={closeModal}>Close</button>
              </div>
            </div>
          )}
          <Footer />
        </div>
    );
};

export default Policies;
