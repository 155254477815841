import React from 'react';
import '../css/HeroSection.css';
import logo from '../assets/logo.png';

function HeroSection({ title }) {
  return (
    <>
      <section className="hero" id="hero">
        <img src={logo} alt="RSCare Logo" />
        <h1>{title}</h1>
        <p>North Devon's trusted independent care provider</p>
      </section>
    </>
  );
}

export default HeroSection;
