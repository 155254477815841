import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/About.css';
import HeroSection from '../components/HeroSection.js';
import aboutImage from '../assets/ryanAndGrandad.jpg';
import Footer from '../components/Footer.js';

function About() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
    
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <section className="about-page">
        <nav className="navbar">
          <button className="menu-toggle" onClick={toggleMenu}>
            &#9776;
          </button>
          <nav className={isMenuOpen ? 'nav open' : 'nav'}>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </nav>
      </nav>
      <HeroSection title={"Getting to know me"} />
      <section className='card-group'>
        <div className='card-item'>
            <Link to="/policies" style={{ textDecoration: 'none'}}>
            <h3>RSC Policies</h3>
            <p>Review RSCare’s policies, terms, and conditions to understand how I operate and protect your rights as a client.</p>
          </Link>
        </div>

        <div className='card-item'>
          <Link to="/complaints" style={{ textDecoration: 'none'}}>
            <h3>Complaints & Feedback</h3>
            <p>Have a concern or feedback? View the complaints procedure and learn how your feedback helps improve my services.</p>
          </Link>
        </div>

        <div className='card-item'>
          <Link to="/privacypolicy" style={{ textDecoration: 'none'}}>
            <h3>Access to Data</h3>
            <p>Request access to your personal data or learn about how I securely manage and store your information.</p>
          </Link>
        </div>
      </section>

      <section className='about-container'>
        <div className='text-left-container'>
          <h2>Meet Ryan Skelton</h2>
          <p>
            At the heart of everything I do is the belief that care should always feel personal.
            My top priority is ensuring that those I support can make choices about how they wish to be cared for, 
            ensuring that every interaction is respectful, unhurried, and focused on their needs. 
            As a self-employed carer, I've built my career on delivering the kind of care that I would want for my own family. 
            I'd like to take a moment to share a personal story that has shaped my commitment to care.
          </p>

          <div className="image-text-row">
            <img src={aboutImage} alt="Ryan and his Grandad" />

            <p>
              I'd like to introduce you all to my late Grandad, Eddie. Grandad was my rock, but sadly, the care system let him down during his journey with dementia.
              After a sudden bout of sepsis from unknown causes, my Grandad was placed on end-of-life care at the Royal Devon University Hospital.
              Over the next 11 days, I stayed by his side, giving him the care he truly deserved, ensuring his comfort and keeping him pain-free. We'd listen to some of his
              favourite music, and enjoy some of his classics. I'd shampoo his hair, moisturise his skin, reposition him in bed to ensure his skin doesn't get damaged,
              and kept him in good company during his final moments.
            </p>
          </div>

          <p>
            Those 11 days became a profoundly rewarding time, as I set aside my own emotions to focus on what truly mattered: Grandad’s peace and comfort.
            When he passed away, as we held hands, I made a promise—not only to myself but to his memory—that I would dedicate my life 
            to ensuring no one else is neglected by the care system, and everyone receives genuine, heartfelt care.
          </p>
        </div>

        <div className='social-media-panel'>
          <div className='instagram-widget'>
          <iframe 
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61566575085140&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" 
            width="340" 
            height="130" 
            style={{border: 'none', overflow: 'hidden'}} 
            allowFullScreen="true" 
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
          </iframe>
            <div className='social-icons'>
              <a href='https://www.facebook.com/Ryan.Skelton17' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook"></i></a>
              <a href='https://www.instagram.com/ryan.skelly3?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></a>
              <a href='https://www.linkedin.com/in/ryan-skelton-045792285/' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin"></i></a>
            </div>
          </div>
        </div>
      </section>
      
      <Footer />
    </section>
  );
}

export default About;
