import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import '../css/Contact.css';
import HeroSection from '../components/HeroSection.js';
import Footer from '../components/Footer.js';


function Contact() {
    const [formData, setFormData] = useState({
        email: '',
        subject: '',
        message: ''
    });

    const [submitted, setSubmitted] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('');
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(process.env.REACT_APP_CONTACT_URL, formData);

            if (response.status === 200) {
                setSubmitted(true);
                setSuccessMessage('Your message has been sent successfully!');
                setErrorMessage('');
            }
        } catch (error) {
            setErrorMessage('There was an error submitting your message. Please try again later.');
            setSuccessMessage('');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    if (submitted) {
        return (
            <div className="contact-page">
                    <nav className="navbar">
                        <button className="menu-toggle" onClick={toggleMenu}>
                            &#9776;
                        </button>
                        <nav className={isMenuOpen ? 'nav open' : 'nav'}>
                        <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </nav>
                </nav>
                <HeroSection title="Thank you for contacting us!" />
                <div className="confirmation-message">
                    <p>{successMessage}</p>
                </div>
            </div>
        );
    }


  return (
        <div className='contact-page'>
            <nav className="navbar">
                <button className="menu-toggle" onClick={toggleMenu}>
                    &#9776;
                </button>
                <nav className={isMenuOpen ? 'nav open' : 'nav'}>
                    <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </nav>
            </nav>
            <HeroSection title='How can I help?' /> 
            <div className='contact-form'>
                <section className='left-panel-large'>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor='email'><strong>Email</strong> (<Link to='/privacypolicy'>Privacy Policy</Link>)</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Temporarily unavailable..."
                            required
                            disabled="true"
                        />

                        <label htmlFor="subject">Subject</label>
                            <input
                                type="text"
                                name="subject"
                                id="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                placeholder="Temporarily unavailable..."
                                required
                                disabled="true"
                            />

                        <label htmlFor="message">How can we help?</label>
                            <textarea
                                name="message"
                                id="message"
                                value={formData.message}
                                onChange={handleChange}
                                placeholder="Temporarily unavailable..."
                                required
                                disabled="true"
                            />

                        <button type="submit" className="submit-btn" disabled='true'>Send</button>
                    </form>
                </section>

                <section className='right-panel-small'>
                    <h1>Get in touch with me</h1>
                    <p>
                        <i className="fa-solid fa-envelope"></i>
                        <a href="mailto:rscareprofessional@gmail.com">rscareprofessional@gmail.com</a>
                    </p>
                    <p>
                        <i className="fa-solid fa-phone"></i>
                        <a href="tel:07818744076">078 187 44076</a>
                    </p>
                    <p>
                        <i className="fa-solid fa-location-pin"></i>
                        <a href="https://maps.app.goo.gl/jZaRAgLUh91K4YtU7" target="_blank" rel="noopener noreferrer">3 Northfield Rd, <br />Ilfracombe, North Devon <br />EX34 8AL</a>
                    </p>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;
