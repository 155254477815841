import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Complaints.css';

import HeroSection from '../components/HeroSection.js';
import Footer from '../components/Footer.js';

function Complaints() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className='complaintsPage'>
            <nav className="navbar">
                <button className="menu-toggle" onClick={toggleMenu}>
                    &#9776;
                </button>
                <nav className={isMenuOpen ? 'nav open' : 'nav'}>
                    <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </nav>
            </nav>
            
            <HeroSection title={"Complaints & Feedback"} />

            <section className='complaints-container'>
                <h1>What to do if you are not happy:</h1>
                <p>At RS Care Professional, I am committed to providing the highest quality of care and support. However, I understand that sometimes things may not meet your expectations. If this happens, I want to make it as easy as possible for you to raise any concerns, so I can address them promptly and effectively.</p>
                <div className='complaints-grid'>
                    <div className='complaints-item'>
                        <div className="icon-title-row">
                            <i class="fa-solid fa-1">.</i>
                            <h2>Talk to me</h2>
                        </div>
                        <p>The best way to resolve a concern is by discussing it directly. If something is bothering you or you’re unhappy with any aspect of the care, please feel free to speak with me. I take all concerns seriously and will listen carefully to understand the issue fully. Together, we can explore how to make things right.</p>
                    </div>

                    <div className='complaints-item'>
                        <div className="icon-title-row">
                            <i class="fa-solid fa-2">.</i>
                            <h2>Put it in Writing</h2>
                        </div>
                        <p>If you prefer, you can put your concern in writing. You can email me directly, or write a formal letter to me using my contact details below.</p>
                    </div>

                    <div className='complaints-item'>
                        <div className="icon-title-row">
                            <i class="fa-solid fa-3">.</i>
                            <h2>How I Will Respond</h2>
                        </div>
                        <p>I will acknowledge your concern within 2 working days and aim to provide a full response within 10 working days. In the rare case that I need more time, I will let you know and keep you informed of progress. My goal is always to resolve matters to your satisfaction as swiftly as possible.</p>
                    </div>

                    <div className='complaints-item'>
                        <div className="icon-title-row">
                            <i class="fa-solid fa-4">.</i>
                            <h2>Further Support</h2>
                        </div>
                        <p>If, after my response, you feel that your concerns have not been fully addressed, I can provide information on independent bodies or advocacy services that can offer additional support and advice. For advocacy and support services in North Devon/Devon, you can refer clients to the Devon Advocacy Consortium, which provides free, independent advocacy services for people over 16. For more information on this, you can view their details below.</p>
                    </div>
                </div>
            </section>

            <section className='complaints-container'>
                <h1>Feedback & Reviews:</h1>
                <p>
                    At RS Care Professional, I believe in continuously improving the care and support I provide. Your feedback is essential in helping me enhance my services and ensure that I meet your expectations. If you have recently used my services, I would love to hear your thoughts!
                    Please take a moment to share your experience. Your valuable insights will help others make informed decisions, and with your permission, I may feature your review on my website and social media platforms.
                </p>
                <div className='complaints-grid'>
                    <div className='complaints-item'>
                        <div className="icon-title-row">
                            <i class="fa-solid fa-1">.</i>
                            <h2>Leaving a review</h2>
                        </div>
                        <p>
                            You can email me directly with your review, or,
                            Alternatively, feel free to leave feedback through writing addressed to my contact details provided below.
                        </p>
                    </div>

                    <div className='complaints-item'>
                        <div className="icon-title-row">
                            <i class="fa-solid fa-2">.</i>
                            <h2>Testimonials</h2>
                        </div>
                        <p>
                            A testimonial is a review that I may publish onto my site. If I decide to publish a review onto the website, I will reach out to the reviewer before I do so in order to obtain consent.
                        </p>
                    </div>

                    <div className='complaints-item'>
                        <div className="icon-title-row">
                            <i class="fa-solid fa-3">.</i>
                            <h2>Anonymity</h2>
                        </div>
                        <p>
                            If you would prefer to remain anonymous when submitting feedback, I fully respect your right to privacy.
                            Anonymous submissions will be handled with the same level of care and consideration. However, please note
                            that providing anonymity may limit my ability to directly address specific concerns or provide a follow-up.
                        </p>
                    </div>

                    <div className='complaints-item'>
                        <div className="icon-title-row">
                            <i class="fa-solid fa-4">.</i>
                            <h2>Third-party reviews</h2>
                        </div>
                        <p>
                            I am currently not registered with any third-party reviewing sites, so please keep this in mind if you chose to review my services elsewhere, as I may not be able to respond to them.
                        </p>
                    </div>
                </div>
            </section>

            <section className='contact-container'>
                <div className='left-container'>
                    <h1>Get in touch with me</h1>
                    <p>
                        <i className="fa-solid fa-envelope"></i>
                        <a href="mailto:rscareprofessional@gmail.com">rscareprofessional@gmail.com</a>
                    </p>
                    <p>
                        <i className="fa-solid fa-phone"></i>
                        <a href="tel:07818744076">078 187 44076</a>
                    </p>
                    <p>
                        <i className="fa-solid fa-location-pin"></i>
                        <a href="https://maps.app.goo.gl/jZaRAgLUh91K4YtU7" target="_blank" rel="noopener noreferrer">3 Northfield Rd, <br />Ilfracombe, North Devon <br />EX34 8AL</a>
                    </p>
                </div>

                <div className='right-container'>
                    <h1>Useful Contacts</h1>
                    <div className='contact-item'>
                        <p className='contact-title'>The Devon Advocacy Consortium:</p>
                        <p className='phone-details'>
                            <i class="fa-solid fa-phone"></i>
                            <a href="tel:01392 822377">01392 822377</a>
                        </p>
                        
                        <p className='email-details'>
                            <i className="fa-solid fa-envelope"></i>
                            <a href="mailto:devonadvocacy@livingoptions.org">devonadvocacy@livingoptions.org</a>
                        </p>
                    </div>

                    <div className='contact-item'>
                        <p className='contact-title'>Adult Safeguarding Team:</p>
                        <p className='phone-details'>
                            <i class="fa-solid fa-phone"></i>
                            <a href="tel:0345 1551 007">0345 1551 007</a>
                        </p>
                        
                        <p className='email-details'>
                            <i className="fa-solid fa-envelope"></i>
                            <a href="mailto:adultsc.safeguardingadultservices-mailbox@devon.gov.uk">adultsc.safeguardingadultservices-mailbox@devon.gov.uk</a>
                        </p>
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Complaints;