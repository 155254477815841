import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../css/Footer.css';

function Footer() {
  return (
    <footer className="Footer">
      <div className="footer-content">
        <div className="contact-info">
          <p className='title'><strong>Contact Me:</strong></p>
          <p>
            <FontAwesomeIcon icon={faPhone} className='icon' /> 
            <a href="tel:07818744076">078 187 44076</a>
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} className='icon' /> 
            <a href="mailto:rscareprofessional@gmail.com">rscareprofessional@gmail.com</a>
          </p>
        </div>

        <div className='development-info'>
          <p className='dev-title'><strong>Site Development:</strong></p>
          <p>This website is under active development, and is being designed & developed by Kieron Skelton, using the React framework.</p>
          <p>If there are any issues with the site, you can contact him <a href='mailto:kieronskelton.work@gmail.com'> here</a></p>
        </div>

        <div className="footer-links">
          <p className='link-title'><strong>Important Links:</strong></p>
          <p>
            <a href="/policies">Privacy Policy</a> &   
            <a href="/terms"> Terms of Service</a>
          </p>
          <p>&copy; {new Date().getFullYear()} RSCareProfessional. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
