import React from 'react';
import { Link } from 'react-router-dom';
import '../css/CallToAction.css';

const CallToAction = () => {
  return (
    <div className='cta-grid'>
        <div className='cta-item'>
          <Link to="/advice" style={{ textDecoration: 'none', color: 'inherit' }}>
            <h2>Care Advice</h2>
            <p>Get expert advice on how to care for your loved ones.</p>
              <i className="fa-solid fa-circle-chevron-right"></i>
          </Link>
        </div>

        <div className='cta-item'>
          <Link to="/blog" style={{ textDecoration: 'none', color: 'inherit' }}>
            <h2>Care Blog</h2>
            <p>Read insightful articles about the work that I do.</p>
            <i className="fa-solid fa-circle-chevron-right"></i>
          </Link>
        </div>

      <div className='cta-item'>
        <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>
          <h2>Contact Me</h2>
          <p>Have questions? Get in touch with me for inquiries.</p>
            <i className="fa-solid fa-circle-chevron-right"></i>
        </Link>
      </div>
    </div>
  );
};

export default CallToAction;
