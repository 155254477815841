import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Advice.css';
import HeroSection from '../components/HeroSection.js';
import Footer from '../components/Footer.js';

import image1 from '../assets/adverts/stress1.jpg';
import image2 from '../assets/adverts/stress2.jpg';
import image3 from '../assets/adverts/stress3.jpg';
import image4 from '../assets/adverts/stress4.jpg';
import image5 from '../assets/adverts/stress5.jpg';

function Advice() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const images = [image1, image2, image3, image4, image5];

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const goToPreviousImage = () => {
        setCurrentImageIndex(
            currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1
        );
    };

    const goToNextImage = () => {
        setCurrentImageIndex(
            currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1
        );
    };

    const getPreviousImageIndex = () => (currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1);
    const getNextImageIndex = () => (currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1);

    return (
        <div className="advice-page">
            <nav className="navbar">
                <button className="menu-toggle" onClick={toggleMenu}>
                    &#9776;
                </button>
                <nav className={isMenuOpen ? 'nav open' : 'nav'}>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </nav>
            </nav>

            <HeroSection title="Adult Social Care & Support" />
            <section className='sub-hero'>
                <div className='stress-slideshow'>
                    <button className="nav-button prev" onClick={goToPreviousImage}>‹</button>

                    {/* Previous Image */}
                    <img
                        src={images[getPreviousImageIndex()]}
                        alt="Previous Image"
                        className="slideshow-image prev-image"
                    />

                    {/* Current Image */}
                    <img
                        src={images[currentImageIndex]}
                        alt="Current Image"
                        className="slideshow-image current-image"
                    />

                    {/* Next Image */}
                    <img
                        src={images[getNextImageIndex()]}
                        alt="Next Image"
                        className="slideshow-image next-image"
                    />

                    <button className="nav-button next" onClick={goToNextImage}>›</button>
                </div>
            </section>

            <section className='advice-grid'>
                <div className="advice-item">
                    <a href='https://www.nhs.uk/conditions/social-care-and-support-guide/care-services-equipment-and-care-homes/care-and-support-you-can-get-for-free/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <h2>What Care & Support Am I Entitled To?</h2>
                    <p>
                        Explore the various types of care and support you may be eligible for, including personal care, home visits, and assistance with daily activities.
                        Learn more about the resources available to you.
                    </p>
                    </a>
                </div>

                <div className="advice-item">
                    <a href='https://www.ageuk.org.uk/information-advice/care/paying-for-care/financial-assessment/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <h2>Financial Support & Advice</h2>
                    <p>
                        Discover financial aid options designed to support your care needs. This includes benefits, funding sources, and other financial assistance
                        to help cover essential care services.
                    </p>
                    </a>
                </div>

                <div className="advice-item">
                    <a href='https://www.cqc.org.uk/care-services/help-choosing-care' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <h2>Finding the Right Care Provider</h2>
                    <p>
                        Get tips on choosing the right care provider for you or your loved one. Learn what to consider, from qualifications and experience to
                        specific care options and trustworthiness.
                    </p>
                    </a>
                </div>

                <div className="advice-item">
                    <a href='https://www.carerightsuk.org/your-rights' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <h2>Legal Rights & Care Decisions</h2>
                    <p>
                        Understand your legal rights in the care process. Learn about your rights to dignity, privacy, and autonomy in choosing the type and level
                        of care that suits your needs.
                    </p>
                    </a>
                </div>

                <div className="advice-item">
                    <a href='https://www.nhs.uk/conditions/social-care-and-support-guide/care-services-equipment-and-care-homes/home-adaptations/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <h2>Adapting Your Home for Care Needs</h2>
                    <p>
                        Find advice on making your home a safe and comfortable space for receiving care. Get guidance on modifications that enhance safety and 
                        accessibility for independent living.
                    </p>
                    </a>
                </div>

                <div className="advice-item">
                    <a href='https://www.nhsinform.scot/care-support-and-rights/decisions-about-care/future-care-planning/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <h2>Planning for Future Care</h2>
                    <p>
                        Prepare for the future by learning how to create a care plan that reflects your evolving needs. Understand the benefits of planning ahead, 
                        from financial planning to documenting care preferences.
                    </p>
                    </a>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Advice;
