import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/ErrorPage.css';

const ErrorPage = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  return (
    <div className="error-page">
      <h1 className="error-title">404</h1>
      <p className="error-message">
        Kieron is working as hard as he can to get this page up & running!
        Programming takes a little time and requires a little patience, but I assure you, I am working as quickly and as hard as I can to pubilsh to this page.
      </p>
      <button className="home-button" onClick={goHome}>Go Home</button>
    </div>
  );
};

export default ErrorPage;
