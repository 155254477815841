import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Home.css';
import HeroSection from '../components/HeroSection.js';
import CallToAction from '../components/CallToActionBoxes.js';
import Footer from '../components/Footer.js';


import ClientImage from '../assets/clientImage.jpg';
import nacasLogo from '../assets/nacasValidation.png';
import vcprLogo from '../assets/vcprValidation.png';
import sgsLogo from '../assets/sgsValidation.png';
import winterVaccine from '../assets/winterVaccinations.png';

function Home() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    return (
      <div className="Home">
        <nav className="navbar">
          <button className="menu-toggle" onClick={toggleMenu}>
            &#9776;
          </button>
          <nav className={isMenuOpen ? 'nav open' : 'nav'}>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </nav>
        </nav>
        
        <HeroSection title={"Welcome to RSCare"} />
        <CallToAction />

        <section className="home-highlights">
          <div className="left-box">
            <div className='about-container'>
              <div className='about-content'>
                <img src={ClientImage} alt="Client" className="about-me-image" />
                <div className='content-text'>
                  <h2>Meet Ryan</h2>
                  <p>
                    Hi! My name is Ryan, and I am an independently registered Micro care provider based in Ilfracombe, North Devon. Servicing Ilfracombe, Braunton, Barnstaple, Combe Martin & Woolacombe, my aim is to ensure that my clients are receiving the highest level of quality care in a way that promotes client independence, dignity, and companionship.
                  </p>
                  <Link to="/about">
                    <button>Learn More</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="right-box">
            <div className='news-container'>

              <div className='news-items'>
                <i className="fa-regular fa-newspaper"></i>
                <a href='https://www.nhsconfed.org/publications/autumn-budget-2024-what-you-need-know' target="_blank" rel="noopener noreferrer"><h3>Autumn Budget: What does it mean for Health & Social Care?</h3></a>
                <p><em>October 30th, 2024</em></p>
                <p>Autumn Budget 2024: what you need to know. This is the summary and analysis of the Autumn Budget and what it means for health and care.</p>
              </div>

              <div className='news-items'>
                <i className="fa-regular fa-newspaper"></i>
                <a href='https://socialcare.blog.gov.uk/2024/10/01/empowering-diversity-in-the-social-care-celebrating-black-history-month/' target="_blank" rel="noopener noreferrer"><h3>Adult Social Care Monthly Newsletter</h3></a>
                <p><em>October 1st, 2024</em></p>
                <p>Empowering diversity in social care: celebrating Black History Month.</p>
              </div>

              <div className='news-items'>
                <i className="fa-regular fa-newspaper"></i>
                <a href='https://socialcare.blog.gov.uk/2024/08/20/getting-a-head-start-on-flu-and-other-infections/' target="_blank" rel="noopener noreferrer"><h3>Adult Social Care Update</h3></a>
                <p><em>August 20th, 2024</em></p>
                <p>Getting a head start on flu and other infections. The flu season is inevitable, so let's be fully prepared.</p>
              </div>

              <div className='news-items'>
                <i className="fa-regular fa-newspaper"></i>
                <a href='https://socialcare.blog.gov.uk/2024/04/10/unpaid-carers-leave-your-right-to-look-after-yourself/' target="_blank" rel="noopener noreferrer"><h3>Adult Social Care Update</h3></a>
                <p><em>April 10th, 2024</em></p>
                <p>Unpaid carer's leave: your right to look after yourself.</p>
              </div>
            </div>
          </div>
        </section>

        <section className='filler-content'>
          <div className='winter-vaccination'>
          <img src={winterVaccine} alt="Vaccination Campaign" />
          </div>
        </section>

        <section className='validation-records'>
          <div className="section-title">
              <h2>Validation Records</h2>
              <div className="line"></div>
          </div>
          <div className='validation-content'>
              <div className="validation-item">
                <a href='https://nacas.org.uk/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img src={nacasLogo} alt="Nacas Logo" className="nacas-logo" />
                    <p>Member of the National Association of Care & Support Workers</p>
                </a>
              </div>
              <div className="validation-item">
                <a href='https://www.thecpr.online/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img src={vcprLogo} alt="VCPR Logo" className="vcpr-logo" />
                  <p>Registered with The Care Professional Register</p>
                </a>
              </div>
              <div className="validation-item">
                <a href='https://www.communitycatalysts.co.uk/smallgoodstuff/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img src={sgsLogo} alt="Small Good Stuff Logo" className="sgs-logo" />
                  <p>Enrolled on the Small Good Stuff Directory by the Community Catalysts</p>
                </a>
              </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
  
  export default Home;
