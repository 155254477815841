// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home.js';
import Services from './pages/Services.js';
import About from './pages/About.js';
import Contact from './pages/Contact.js';
import ErrorPage from './components/ErrorPage.js';
import Policies from './pages/Policies.js';
import Advice from './pages/Advice.js';
import Complaints from './pages/Complaints.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/advice' element={<Advice />} />
        <Route path='/blog' element={<ErrorPage />} />
        <Route path='/policies' element={<Policies />} />
        <Route path='/complaints' element={<Complaints />} />
        <Route path='/privacypolicy' element={<Policies />} />
        <Route path='/terms' element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;