import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../css/Services.css';
import Footer from "../components/Footer.js";
import Hero from "../components/HeroSection.js";

import image from "../assets/holding-hands.jpg";
import image2 from "../assets/brewedcampaign.png";

const Services = () => {
    const [expandedItem, setExpandedItem] = useState(null);

    const handleItemClick = (index) => {
        setExpandedItem(expandedItem === index ? null : index);
    };

    const services = [
        {
            title: "Initial Consultation",
            details: "To begin with, I will gather some initial information via your preferred contact method, as well as a face-to-face consultation. This will allow me to get to know you better and ensures that I can assess whether I'm the right person for you. It also gives you the opportunity to assess me!"
        },
        {
            title: "Care Planning",
            details: "After our initial meeting, if we both agree that I am able to meet your needs, I will provide a written care plan outlining the agreed support. This will include your very own personalised care plan, cost of services, and any other important information for you and your family, or representative to review. You will be able to check and alter your care plan before services begin to ensure it aligns with your expectations."
        },
        {
            title: "Starting your care",
            details: "As a self-employed professional, I provide each client with a clear contract of services that outlines my responsibilities, rates, and other important details. This contract ensures that we both have reassurances about the work agreements in place, and we will work together to ensure that you receive the best care possible for my services."
        }
    ];

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="services-page">
            <nav className="navbar">
                <button className="menu-toggle" onClick={toggleMenu}>
                    &#9776;
                </button>
                <nav className={isMenuOpen ? 'nav open' : 'nav'}>
                    <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </nav>
            </nav>
            <Hero title="Services & Care Packages" />
                <section className="services-main">
                    <div className="services-left-container">
                        <h1>Relatives, Carers & Clients</h1>
                        <p>
                            As an independent care provider, I am dedicated to offering compassionate, personalised care that meets each person's needs.
                            From daily support to companionship, I aim to make a positive difference, providing peace of mind and a reliable, caring presence for both clients
                            and their families.
                        </p>
                        <br />
                        <Link to="/contact">
                            <button className="contact-button" onClick={() => window.location.href = '/contact'}>
                                Contact Page
                            </button>
                        </Link>
                    </div>

                    <div className="services-right-container">
                        <div className="details-grid">
                            <div className="detail-item">
                                <i className="fa-solid fa-house-user"></i>
                                <h3>Personal/Continence Care</h3>
                            </div>

                            <div className="detail-item">
                                <i className="fa-solid fa-bed"></i>
                                <h3>Night Care</h3>
                            </div>

                            <div className="detail-item">
                                <i className="fa-solid fa-mug-hot"></i>
                                <h3>befriending Service</h3>
                            </div>

                            <div className="detail-item">
                                <i className="fa-solid fa-heart-pulse"></i>
                                <h3>Vitals Monitoring</h3>
                            </div>

                            <div className="detail-item">
                                <i className="fa-solid fa-utensils"></i>
                                <h3>Meal Preporation</h3>
                            </div>

                            <div className="detail-item">
                                <i className="fa-solid fa-broom"></i>
                                <h3>Domestic Tasks</h3>
                            </div>

                            <div className="detail-item">
                                <i className="fa-solid fa-person-walking-with-cane"></i>
                                <h3>Complex Care</h3>
                            </div>

                            <div className="detail-item">
                                <i className="fa-solid fa-heart"></i>
                                <h3>End of Life Care</h3>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="fyi-bar">
                    <div className="fyi-title">
                        <h2>Getting Started</h2>
                        <div className="line"></div>
                    </div>

                    <div className="content-row">
                        {services.map((service, index) => (
                            <div
                                key={index}
                                className={`content-item ${expandedItem === index ? "expanded" : ""}`}
                                onClick={() => handleItemClick(index)}
                            >
                                <div className="content-number">{index + 1}</div>
                                <div className="content-title" style={{ display: expandedItem === index ? 'none' : 'block' }}>{service.title}</div>
                                {expandedItem !== index && (
                                    <i className="fas fa-arrow-circle-right"></i>
                                )}
                                {expandedItem === index && (
                                    <div className="content-details">
                                        <p>{service.details}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </section>

                <section className="packages-bar">
                    <div className="filler-section">
                        <img src={image2} alt="Brewed Friendships Advert" className="filler-advert" />
                    </div>
                    <div className="packages-grid">
                        <div className="package-item">
                            <h3>Home Visits</h3>
                            <p>
                                Depending on the clients needs, home visits ensure that you can get the support you need in the comfort of your own home.
                                My home visits are designed to provide personalised care tailored to your needs. Wether it's help with daily activities,
                                health checks, cooking & meal prep, medicaion, personal/continence care, or simply a friendly chat, I am dedicated to enhancing 
                                your quality of life while ensuring you feel safe and well cared for in your own environment.
                            </p>
                        </div>

                        <div className="package-item">
                            <h3>Befriending Service</h3>
                            <p>
                                If you or a loved one is feeling lonely or isolated, my befriending services provides companionship and support to enhance 
                                well-being. Whether it’s sharing a cup of tea, enjoying a chat, or participating in activities together, I’m here to brighten 
                                your day and foster meaningful connections. Create a more fulfilling and joyful life together.
                            </p>
                        </div>

                        <div className="package-item">
                            <h3>Respite Care</h3>
                            <p>
                                Respite care offers vital relief for family members and caregivers who deserve a break. I provide short-term, compassionate care 
                                and support for your loved one, ensuring their needs are met while you take time to recharge. This service allows you to rest easy, 
                                knowing that your family member is in safe hands, receiving the highest quality of care and attention during your absence.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="focus-point">
                    <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <button>Book a Consultation</button>
                    </Link>
                </section>
            <Footer />
        </div>
    )
}

export default Services;